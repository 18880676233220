<script>
import TablePage from '@/found/components/table_page';

export default {
  extends: TablePage,
  components: {
  },
  data() {
    return {
      formConfig: {},
      modalConfig: {
        title: '新增',
        visible: false,
        type: 'Modal',
      },
    };
  },
  mounted() {
    this.getConfigList('order-send-list');
  },
  methods: {
    setColumn(v) {
      const rowData = v;
      if (rowData.field === 'taxPrice') {
        rowData.formatter = ({ row }) => {
          if (row.hide) {
            return '****';
          }
          return row.taxPrice;
        };
      }
      if (rowData.field === 'totalAmount') {
        rowData.formatter = ({ row }) => {
          if (row.hide) {
            return '****';
          }
          return row.totalAmount;
        };
      }
      return rowData;
    },
    modalClick({ val, row }) {
      if (val.code === 'adjustment') {
        this.modalConfig.title = '手动';
        this.formConfig.poolId = row.id;
        this.formConfig.code = 'edit';
        this.openModal();
      }
      if (val.code === 'detail') {
        this.$router.push(`/capital-pool/cash/detail?poolId=${row.id}`);
      }
    },
  },
};
</script>
